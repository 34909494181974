export const faqs = [
	{
		'question': {
			'english': 'I have some questions before making a purchase, how can I proceed?',
			'spanish': 'Tengo algunas dudas antes de hacer la compra, ¿Cómo puedo hacer?',
			'portuguese': 'Tenho algumas dúvidas antes de fazer a compra, como posso proceder?',
			'chinese': '购买之前我有一些疑问，我该如何操作？',
			'japanese': '購入前にいくつか疑問がありますが、どうすればいいですか？',
			'korean': '구매 전에 몇 가지 질문이 있습니다. 어떻게 해야 하나요?'
		},
		'answer': {
			'english': 'Write to us on WhatsApp +5491165361357',
			'spanish': 'Escribinos por whatsapp +5491165361357',
			'portuguese': 'Escreva-nos pelo WhatsApp +5491165361357',
			'chinese': '请通过 WhatsApp +5491165361357 联系我们',
			'japanese': 'WhatsApp +5491165361357 にご連絡ください',
			'korean': 'WhatsApp +5491165361357으로 연락 주세요.'
		},
		'answer2': {
			'english': 'You can click on the following link: http://wa.me/5491165361357 or write to us at wang.ar710@gmail.com to ask us anything you need, remember to include the subject so we can assist you in the best way possible.',
			'spanish': 'Podés hacer clic en el siguiente enlace: http://wa.me/5491165361357 o escribinos a wang.ar710@gmail.com para consultarnos todo lo que necesites, recordá colocar el asunto así podemos asesorarte de la mejor manera.',
			'portuguese': 'Você pode clicar no seguinte link: http://wa.me/5491165361357 ou escrever para nós em wang.ar710@gmail.com para nos consultar sobre o que precisar, lembre-se de colocar o assunto para que possamos te assessorar da melhor forma possível.',
			'chinese': '您可以点击以下链接：http://wa.me/5491165361357 或发送邮件至 wang.ar710@gmail.com 进行咨询，请记得填写主题，以便我们为您提供最佳帮助。',
			'japanese': '以下のリンク http://wa.me/5491165361357 をクリックするか、wang.ar710@gmail.com にメールでお問い合わせください。件名を記入していただけると、最適なサポートが可能です。。',
			'korean': '다음 링크 http://wa.me/5491165361357 를 클릭하거나, wang.ar710@gmail.com 으로 이메일을 보내주세요. 문의하실 내용을 기재하시면 최상의 도움을 드릴 수 있습니다..'
		},
		'answer3': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		}
	},
	{
		'question': {
			'english': 'How do I clarify the details of my purchase?',
			'spanish': '¿Cómo aclaro los detalles de mi compra?',
			'portuguese': 'Como posso esclarecer os detalhes da minha compra?',
			'chinese': '我如何澄清我的购买详情？',
			'japanese': '購入の詳細はどのように確認しますか？',
			'korean': '구매 상세 정보를 어떻게 확인할 수 있나요?'
		},
		'answer': {
			'english': 'You can leave them clarified in the Description when finalizing your purchase. We also always contact you via WhatsApp to coordinate all the details.',
			'spanish': 'Podés dejarlo aclarado en Descripción al momento de finalizar tu compra. Igualmente siempre nos ponemos en contacto por whatsapp para coordinar todos los detalles.',
			'portuguese': 'Você pode deixá-los esclarecidos na Descrição ao finalizar sua compra. Também sempre entramos em contato por WhatsApp para coordenar todos os detalhes.',
			'chinese': '您可以在完成购买时在说明中注明。我们也会通过 WhatsApp 联系您，协调所有细节。',
			'japanese': '購入を確定する際に「説明」に記載することができます。また、全ての詳細については、WhatsApp でご連絡いたします。',
			'korean': '구매를 마무리할 때 설명에 명시할 수 있습니다. 또한 모든 세부 사항을 조정하기 위해 WhatsApp으로 연락드립니다.'
		},
		'answer2': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		},
		'answer3': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		}
	},
	{
		'question': {
			'english': 'What payment methods are available?',
			'spanish': '¿Qué formas de pago hay?',
			'portuguese': 'Quais são as formas de pagamento disponíveis?',
			'chinese': '有哪些付款方式？',
			'japanese': 'どのような支払い方法がありますか？',
			'korean': '어떤 결제 방법이 있나요?'
		},
		'answer': {
			'english': 'We offer the following payment methods: cash, bank deposit, bank transfer, Mercado Pago, Payway.',
			'spanish': 'Disponemos de los siguientes medios de pago: efectivo, depósito, transferencia bancaria, mercado pago, payway.',
			'portuguese': 'Disponibilizamos os seguintes meios de pagamento: dinheiro, depósito bancário, transferência bancária, Mercado Pago, Payway.',
			'chinese': '我们提供以下付款方式：现金、银行存款、银行转账、Mercado Pago、Payway。',
			'japanese': '以下の支払い方法をご利用いただけます: 現金、銀行振込、銀行送金、Mercado Pago、Payway。',
			'korean': '다음 결제 방법을 제공합니다: 현금, 은행 예금, 은행 송금, Mercado Pago, Payway.'
		},
		'answer2': {
			'english': 'Once the payment is made, the proof of payment must be sent without exception.',
			'spanish': 'Una vez realizado el pago se debe enviar el comprobante de pago sin excepción.',
			'portuguese': 'Assim que o pagamento for efetuado, o comprovante de pagamento deve ser enviado sem exceção.',
			'chinese': '完成付款后，必须发送付款凭证，绝无例外。',
			'japanese': '支払いが完了したら、必ず支払い証明書を送付してください。',
			'korean': '결제가 완료되면 영수증을 예외 없이 보내야 합니다.'
		},
		'answer3': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		}
	},
	{
		'question': {
			'english': 'What is the shipping cost?',
			'spanish': '¿Cuál es el costo de envío?',
			'portuguese': 'Qual é o custo do frete?',
			'chinese': '运费是多少？',
			'japanese': '配送料はいくらですか？',
			'korean': '배송비는 얼마인가요?'
		},
		'answer': {
			'english': 'If you are in CABA and your purchase exceeds $30,000 and you are within a 30km radius, shipping is free.',
			'spanish': 'Si sos de CABA y tu compra supera los $30.000 y te encontras en el radio de 30km el envío es gratis.',
			'portuguese': 'Se você está em CABA e sua compra excede $30.000 e está dentro de um raio de 30km, o frete é grátis.',
			'chinese': '如果您在CABA，且购买金额超过$30,000，并且在30公里范围内，运费免费。',
			'japanese': 'あなたがCABAにお住まいで、購入金額が$30,000を超え、30km圏内にいる場合、配送料は無料です。',
			'korean': 'CABA에 거주하고 있으며 구매 금액이 $30,000을 초과하고 30km 반경 내에 있다면 배송비는 무료입니다.'
		},
		'answer2': {
			'english': 'If you are in PBA or the interior of the country, we can send it through your preferred transportation, but shipping will have an additional cost.',
			'spanish': 'En caso de ser de PBA o del interior del país podemos enviarlo mediante su transporte de preferencia pero el envío tiene un costo adicional.',
			'portuguese': 'Se você está no PBA ou no interior do país, podemos enviar pelo transporte de sua preferência, mas o frete terá um custo adicional.',
			'chinese': '如果您在PBA或国内其他地区，我们可以通过您选择的运输方式发货，但运费将额外收取。',
			'japanese': 'PBAまたは国内の他の地域にお住まいの場合は、ご希望の配送業者を通じて配送できますが、追加の送料がかかります。',
			'korean': 'PBA 또는 국내의 다른 지역에 있다면 선호하는 배송업체를 통해 보낼 수 있지만 추가 배송비가 부과됩니다.'
		},
		'answer3': {
			'english': 'The shipping cost is not included in the price, it is the responsibility of the customer.',
			'spanish': 'El valor del envío no está incluido en el precio, está a cargo del cliente.',
			'portuguese': 'O custo do frete não está incluído no preço, é responsabilidade do cliente.',
			'chinese': '运费不包含在价格中，由客户承担。',
			'japanese': '配送料は価格に含まれておらず、顧客の負担となります。',
			'korean': '배송비는 가격에 포함되어 있지 않으며 고객이 부담해야 합니다.'
		}
	},
	{
		'question': {
			'english': 'How are shipments made?',
			'spanish': '¿Cómo se realizan los envíos?',
			'portuguese': 'Como são realizados os envios?',
			'chinese': '如何进行发货？',
			'japanese': '配送はどのように行われますか？',
			'korean': '배송은 어떻게 이루어지나요?'
		},
		'answer': {
			'english': 'We work with OCA, Andreani, Correo Argentino, Via Cargo.',
			'spanish': 'Trabajamos con OCA, Andreani, correo argentino, via cargo.',
			'portuguese': 'Trabalhamos com OCA, Andreani, Correo Argentino, Via Cargo.',
			'chinese': '我们与 OCA, Andreani, Correo Argentino, Via Cargo 合作。',
			'japanese': 'OCA、Andreani、Correo Argentino、Via Cargoと提携しています。',
			'korean': 'OCA, Andreani, Correo Argentino, Via Cargo와 협력하고 있습니다.'
		},
		'answer2': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		},
		'answer3': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		}
	},
	{
		'question': {
			'english': 'How long does it take for the order to arrive?',
			'spanish': '¿Cuánto tarda en llegar el pedido?',
			'portuguese': 'Quanto tempo leva para o pedido chegar?',
			'chinese': '订单要多久才能到达？',
			'japanese': '注文品はどれくらいで届きますか？',
			'korean': '주문이 도착하는 데 얼마나 걸리나요?'
		},
		'answer': {
			'english': 'The delivery time will depend on the type of shipment selected.',
			'spanish': 'El tiempo de entrega dependerá del tipo de envío seleccionado.',
			'portuguese': 'O tempo de entrega depende do tipo de envio selecionado.',
			'chinese': '交货时间将取决于所选择的运输方式。',
			'japanese': '配達時間は、選択した配送方法によって異なります。',
			'korean': '배송 시간은 선택한 배송 방법에 따라 달라집니다.'
		},
		'answer2': {
			'english': 'In general, the delay is between 2 to 7 business days after payment is credited for in-stock products.',
			'spanish': 'En general la demora se encuentra entre 2 a 7 días hábiles luego de acreditar el pago con productos en stock.',
			'portuguese': 'Em geral, o prazo de entrega é de 2 a 7 dias úteis após a confirmação do pagamento para produtos em estoque.',
			'chinese': '通常，付款确认后，现货产品的延迟时间为 2 到 7 个工作日。',
			'japanese': '通常、在庫がある商品の場合、支払いが確認されてから2～7営業日で発送されます。',
			'korean': '일반적으로 재고가 있는 제품의 경우 결제가 확인된 후 영업일 기준 2~7일이 소요됩니다.'
		},
		'answer3': {
			'english': '',
			'spanish': '',
			'portuguese': '',
			'chinese': '',
			'japanese': '',
			'korean': ''
		}
	}
];